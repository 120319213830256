#error-code{
    padding: 130px 0;

    .content{
        display: flex;
        row-gap: 30px;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        h1{
            font-size: 50px;
            font-weight: 700;
        }
    
        h2{
            font-size: 30px;
            font-weight: 700;
        }
    
        p{
            font-size: 26px;
            font-weight: 400;
        }

        a{
            font-size: 26px;
            font-weight: 700;
            text-decoration: underline;
        }

    }

}

body{
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    footer{
        margin: auto 0 0 0;
    }

}